.service_img {
    margin: 90px 0 50px 0;
    border-radius: 10px;
}

.service_title {
    font-size: 38px;
    color: #246D62;
}

@media (max-width: 768px) {
    .service_title {
        font-size: 25px;

    }
}

.service_p {
    font-size: 22px;
    line-height: 42px;
    padding: 40px 0 40px 0;
}

@media (max-width: 768px) {
    .service_p {
        font-size: 18px;
        line-height: 30px;
        text-align: justify;
        padding: 25px 0 25px 0;
    }
}

.service_backgroundnumber {
    font-size: 100px;
    font-weight: black;
    color: #4FC1B1;
}

@media (max-width:768px) {
    .service_backgroundnumber {
        font-size: 70px;
    }
}

.service_content {
    /* padding-left: 20px;
    padding-right: 20px; */

}

.service_item-one {
    height: 90%;
    background-color: #246D62;
    color: white;
    border-radius: 20px;
    padding: 25px;
    margin-bottom: 25px;
}

@media (max-width:768px) {
    .service_item-one {
        padding: 15px;
    }
}

.service_item-two {
    height: 100%;
    background-color: #246D62;
    color: white;
    border-radius: 20px;
    padding: 25px;
    margin-bottom: 25px;
}

@media (max-width: 768px) {
    .service_item-two {
        padding: 15px;
    }
}

.service_list-item {
    font-size: 18px;
    background-color: #D1EFEB;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 12px;
}

.service_list-item:last-child {
    margin-bottom: 0px;

}

.service_list-three {
    margin-top: 5px;
}