:root {
    --font-size-title: 60px;
    --font-size-h2: 44px;
    --color-primary: #47AFA1;
    --color-background: #F6FCFA;
}

@media (max-width: 768px) {
    :root {
        --font-size-title: 36px;
        --font-size-h2: 28px;
    }
}